/* NewIndex.css */
.new-index-body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .login-container {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;
  }
  
  .logo {
    width: 200px;
    margin-bottom: 1rem;
  }
  
  .login-header h2 {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .login-form {
    margin-top: 1rem;
  }
  
  /* General input field styles */
.input-field {
    width: 100%; /* Hace que el input ocupe todo el ancho del contenedor */
    padding: 10px; /* Espacio interior */
    margin-bottom: 10px; /* Espacio entre campos */
    border: 1px solid #ccc; /* Borde ligero */
    border-radius: 5px; /* Bordes redondeados */
    font-size: 16px; /* Tamaño de la fuente */
    box-sizing: border-box; /* Incluye padding y border en el ancho y alto total */
  }
  
  /* Button styles to match the input fields */
  .login-button {
    width: 100%; /* Hace que el botón tenga el mismo ancho que el input */
    padding: 10px; /* Mismo padding que el input */
    border: none; /* Sin borde */
    border-radius: 5px; /* Mismo borde redondeado que el input */
    background-color: #007bff; /* Color de fondo */
    color: white; /* Color del texto */
    font-size: 16px; /* Mismo tamaño de fuente que el input */
    cursor: pointer; /* Cursor cambia al pasar el ratón */
    box-sizing: border-box; /* Incluye padding en el ancho y alto total */
  }
  
  .login-button:hover {
    background-color: #0056b3; /* Cambia el color del botón al pasar el ratón */
  }