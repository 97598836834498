/*** PANEL PRIMARY ***/
.with-nav-tabs .nav-item .nav-link {
  padding: 10px 15px;
  background-color: white;
  color: black;
  border: 1px solid rgb(0, 123, 255);
  border-radius: 0px;
  cursor: pointer;
}

.with-nav-tabs .nav-item.active .nav-link,
.with-nav-tabs .nav-item .nav-link:hover,
.with-nav-tabs .nav-item .nav-link:focus {
  color: #fff;
  background-color: #007bff; /* Cambia el color de fondo al activar */
  border-color: #007bff; /* Cambia el color del borde al activar */
}

.with-nav-tabs .nav-item.active .nav-link {
  background-color: #007bff; /* Color de fondo activo */
  color: #fff; /* Color de texto activo */
  border-color: #007bff; /* Color del borde activo */
}

.with-nav-tabs .nav-item.dropdown .dropdown-menu {
  background-color: #428bca; /* Color de fondo del menú desplegable */
  border-color: #3071a9; /* Color del borde del menú desplegable */
}

.with-nav-tabs .nav-item.dropdown .dropdown-menu > li > a {
  color: #fff; /* Color del texto enlaces del menú desplegable */
}

.with-nav-tabs .nav-item.dropdown .dropdown-menu > li > a:hover,
.with-nav-tabs .nav-item.dropdown .dropdown-menu > li > a:focus {
  background-color: #3071a9; /* Color de fondo enlace activo del menú desplegable */
}

