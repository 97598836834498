body {
    font-size: 12px; /* Ajusta el tamaño de fuente global aquí */
    margin: 0; /* Opcional: elimina el margen predeterminado del body */
    font-family: Arial, sans-serif; /* Puedes cambiar la fuente también */
}

button {
    font-size: 12px; /* Ajusta el tamaño de fuente global aquí */
    margin: 0; /* Opcional: elimina el margen predeterminado del body */
    font-family: Arial, sans-serif; /* Puedes cambiar la fuente también */
}

.pagination2 {
    font-size: 12px !important; /* Cambia el tamaño de fuente según sea necesario */
}

.titulo {
    font-size: 18px;
    color: #ffffff;
}

.titulo_cliente {
    font-size: 20px;
    color: #ffffff;
}

table.titulos_tablas th {
    color: #ffffff;
    background-color: #000066;
}
:root {
    --bs-table-bg: transparent !important;
  }
.titulos_tablas tbody tr {
    background-color: transparent !important; /* Deshabilitar fondo blanco en filas */
  }

table tr.even-row {
    background-color: #ffffff !important;
    background-image: none !important;
  }
  
  table tr.odd-row {
    background-color: #99cccc !important;
    background-image: none !important;
  }

  .table-custom {
    --bs-table-bg: transparent;
  }
  
 