/* demo page styles */
body {
    
    margin:0;
    padding:0;
	background: url('../imgs/denim.png');
	font-family: 'Droid Sans', sans-serif;;
}

:root {
    --bs-table-bg: transparent !important;
  }

html, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, tbody, tfoot, thead, 
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

.wrap {
    width: 95%;
	margin: 1em auto;
    height:570px;
    margin:0px auto;
    padding:15px;
    border-radius:3px;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
}

table {
	border-collapse: collapse;
	border-spacing: 5px;
}


#nav2 {
    
    padding: 0 15px;
    background: -webkit-gradient(linear, center top, center bottom, from(#fff), to(#ccc));
	background-image: linear-gradient(#fff, #ccc);
	
    border-radius:10px; /*some css3*/
    -moz-border-radius:10px;
    -webkit-border-radius:10px;
    box-shadow:0 2px 2px rgba(0,0,0, .5);
    -moz-box-shadow:0 2px 2px rgba(0,0,0, .5);
    -webkit-box-shadow:0 2px 2px rgba(0,0,0, .5);
}

/* main menu styles */
#nav {
    display:inline-block;
    width:100%;
    margin:0px auto;
    padding:0;
    background: -webkit-gradient(linear, center top, center bottom, from(#fff), to(#ccc));
	background-image: linear-gradient(#fff, #ccc);

    border-radius:10px; /*some css3*/
    -moz-border-radius:10px;
    -webkit-border-radius:10px;
    box-shadow:0 2px 2px rgba(0,0,0, .5);
    -moz-box-shadow:0 2px 2px rgba(0,0,0, .5);
    -webkit-box-shadow:0 2px 2px rgba(0,0,0, .5);
}
#nav li {
    margin:0px;
    float:left;
    position:relative;
    list-style:none;
	font-size: 15px;

}
#nav a {
    font-weight:bold;
    color:#e7e5e5;
    text-decoration:none;
    display:block;
    padding:12px 20px;
	color:#000;

    /*border-radius:10px; /*some css3*/
   /* -moz-border-radius:10px;
    -webkit-border-radius:10px;
    text-shadow:0 2px 2px rgba(0,0,0, .7);*/
}

/* selected menu element */
#nav .current a, #nav li:hover > a {
    background:#7788aa url(../imgs/bg.png) repeat-x 0 -20px;
    color:#000;
    border-top:1px solid #f8f8f8;

    box-shadow:0 2px 2px rgba(0,0,0, .7); /*some css3*/
    -moz-box-shadow:0 2px 2px rgba(0,0,0, .7);
    -webkit-box-shadow:0 2px 2px rgba(0,0,0, .7);
    text-shadow:0 2px 2px rgba(255,255,255, 0.7);
}

/* sublevels */
#nav ul li:hover a, #nav li:hover li a {
    background:none;
    border:none;
    color:#000;
}
#nav ul li a:hover {
    background:#335599 url(../imgs/bg.png) repeat-x 0 -100px;
    color:#fff;

    border-radius:10px; /*some css3*/
    -moz-border-radius:10px;
    -webkit-border-radius:10px;
    text-shadow:0 2px 2px rgba(0,0,0, 0.7);
}

#nav ul li:first-child > a {
    -moz-border-radius-topleft:10px; /*some css3*/
    -moz-border-radius-topright:10px;
    -webkit-border-top-left-radius:10px;
    -webkit-border-top-right-radius:10px;
}
#nav ul li:last-child > a {
    -moz-border-radius-bottomleft:10px; /*some css3*/
    -moz-border-radius-bottomright:10px;
    -webkit-border-bottom-left-radius:10px;
    -webkit-border-bottom-right-radius:10px;
}

/* drop down */
#nav li:hover > ul {
    opacity:1;
    visibility:visible;
}
#nav ul {
    opacity:0;
    visibility:hidden;
    padding:0;
    width:275px;
    position:absolute;
    background:#aabbcc url(../imgs/bg.png) repeat-x 0 0;
    border:1px solid #7788aa;

    border-radius:10px; /*some css3*/
    -moz-border-radius:10px;
    -webkit-border-radius:10px;
    box-shadow:0 2px 2px rgba(0,0,0, .5);
    -moz-box-shadow:0 2px 2px rgba(0,0,0, .5);
    -webkit-box-shadow:0 2px 2px rgba(0,0,0, .5);

    -moz-transition:opacity .25s linear, visibility .1s linear .1s;
    -webkit-transition:opacity .25s linear, visibility .1s linear .1s;
    -o-transition:opacity .25s linear, visibility .1s linear .1s;
    transition:opacity .25s linear, visibility .1s linear .1s;
}
#nav ul li {
    float:none;
    margin:0;
}
#nav ul a {
    font-weight:normal;
    text-shadow:0 2px 2px rgba(255,255,255, 0.7);
}
#nav ul ul {
    left:275px;
    top:0px;
}

#log2 {
	left:360px !important;
}

.btn {
  background: #2d5875;
  background-image: -webkit-linear-gradient(top, #2d5875, #2980b9);
  background-image: -moz-linear-gradient(top, #2d5875, #2980b9);
  background-image: -ms-linear-gradient(top, #2d5875, #2980b9);
  background-image: -o-linear-gradient(top, #2d5875, #2980b9);
  background-image: linear-gradient(to bottom, #2d5875, #2980b9);
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #ffffff;
  font-size: 15px;
  padding: 5px 20px 5px 20px;
  text-decoration: none;
}

.btn:hover {
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
  text-decoration: none;
}


.breenBtn {
  background: #196F3D;
  background-image: -webkit-linear-gradient(top, #196F3D, #58D68D);
  background-image: -moz-linear-gradient(top, #196F3D, #58D68D);
  background-image: -ms-linear-gradient(top, #196F3D, #58D68D);
  background-image: -o-linear-gradient(top, #196F3D, #58D68D);
  background-image: linear-gradient(to bottom, #196F3D, #58D68D);
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #ffffff;
  font-size: 15px;
  padding: 5px 20px 5px 20px;
  text-decoration: none;
}

.breenBtn:hover {
  background: #28B463;
  background-image: -webkit-linear-gradient(top, #28B463, #ABEBC6);
  background-image: -moz-linear-gradient(top, #28B463, #ABEBC6);
  background-image: -ms-linear-gradient(top, #28B463, #ABEBC6);
  background-image: -o-linear-gradient(top, #28B463, #ABEBC6);
  background-image: linear-gradient(to bottom, #28B463, #ABEBC6);
  text-decoration: none;
}



.redBtn {
  background: #C0392B;
  background-image: -webkit-linear-gradient(top, #196F3D, #58D68D);
  background-image: -moz-linear-gradient(top, #196F3D, #58D68D);
  background-image: -ms-linear-gradient(top, #196F3D, #58D68D);
  background-image: -o-linear-gradient(top, #196F3D, #58D68D);
  background-image: linear-gradient(to bottom, #C0392B, #CD6155);
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #ffffff;
  font-size: 15px;
  padding: 5px 20px 5px 20px;
  text-decoration: none;
}

.redBtn:hover {
  background: #EC7063;
  background-image: -webkit-linear-gradient(top, #EC7063, #F1948A);
  background-image: -moz-linear-gradient(top, #EC7063, #F1948A);
  background-image: -ms-linear-gradient(top, #EC7063, #F1948A);
  background-image: -o-linear-gradient(top, #EC7063, #F1948A);
  background-image: linear-gradient(to bottom, #EC7063, #F1948A);
  text-decoration: none;
}

/** INVENTARIO CICLICO */

.busqueda_producto_ciclico{
    background: -webkit-gradient(linear, center top, center bottom, from(#fff), to(#ccc));
    border-radius: 5px;
    padding: 10px;
    display: flex;
    gap: 1rem;
}

.buscar_prod_input{
    padding: 5px;
}

.buscar_prod_boton{
    padding: 5px;
}

.main_prod_wms{
    background: -webkit-gradient(linear, center top, center bottom, from(#fff), to(#ccc));
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.titulo_cant_wms{
    font-size: 1rem;
    display: flex;
    justify-content: center;
    padding: 5px;
    font-weight: bold;
}

/* .tabla_wms_div{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
} */

.table_wms_prod{
    font-size: 1rem;
}

.totales{

    background: -webkit-gradient(linear, center top, center bottom, from(#fff), to(#ccc));
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
}


.devo_merma{
    display: flex;
    flex-direction: column;
}


.mermas {
    margin-top: 1rem;
}